

// const mainUrl = "http://localhost:5000";
const mainUrl = "https://backend.darkhanskylogistics.com";

//auth urls
export const loginUri = mainUrl + "/auth/login";
export const registerUri = mainUrl + "/auth/register";
export const logoutUri = mainUrl + "/auth/logout";
export const forgetUri = mainUrl + "/auth/forget";
export const resetPassUri = mainUrl + "/auth/resetpass";

//user uri
export const getuseruri = mainUrl + "/users";
export const authuri = mainUrl + "/auth";


// new urls
export const products_uri = mainUrl + "/products";
export const orders_uri = mainUrl + "/orders";
export const user_uri = mainUrl + "/user";
export const lesson_uri = mainUrl + "/lesson";