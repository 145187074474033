import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    ModalCloseButton,
    useToast,
    Spinner,
  } from '@chakra-ui/react'
import { useContext, useState } from 'react';
import { CiCirclePlus } from 'react-icons/ci'
import { AuthContext } from '../../../../context/auth';
import { POST } from '../../../../utils/requests';
import { getuseruri } from '../../../../uri';

export function TrackAddModal({callback}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {currentUser} = useContext(AuthContext);
    const [data, setData] = useState({trackcode:"", note:""});
    const [load, setLoad] = useState(false);
    const toast = useToast();

    const handlecall = () => {
      callback();
    }

    const Sub = async()=> {
      setLoad(true);
      const res = await POST({uri: getuseruri+`/trackadd`, data:{trackcode: data.trackcode, note: data.note, token: currentUser}});
      if(res.status === 200){
        onClose();
        handlecall();
        toast({title: "Трак код бүртгэгдлээ", description: "SkyLogistic каргог сонгодог таньд баярлалаа", status: "success", duration: 5000,isClosable: true,});
        setData({...data, trackcode:"", note:""});
      }
      setLoad(false);
    }

    return (
      <>
        <div onClick={onOpen} className='flex justify-center'>
            <div className='bg-[#1C2E70] ring-2 ring-gray-300 flex items-center justify-center gap-2 xs:min-w-[320px] md:min-w-[600px]
                rounded-xl fixed bottom-20 py-2'>
                <CiCirclePlus size={30} color='white'/>
                <h1 className='text-white'>Трак код бүртгэх</h1>
            </div>
        </div>
  
        <Modal isOpen={isOpen} onClose={onClose} placement='bottom'>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Modal Title</ModalHeader> */}
            <ModalCloseButton />
            <ModalBody>
                <div className='mb-10 font-Roboto mt-8'>
                    <h1 className='text-sm mb-2'>Трак код</h1>
                    <input value={data.trackcode} onChange={(e) => setData({...data, trackcode:e.target.value})} placeholder='Трак код бичих' className='w-full
                    p-3 rounded-xl outline-none bg-white text-black 
                    focus:ring-2 ring-1' type=''/>
                    <h1 className='text-sm mb-2 mt-2'>Тэмдэглэл бичих</h1>
                    <input value={data.note} onChange={(e) => setData({...data, note:e.target.value})}  placeholder='Тэмдэглэл бичих' className='w-full
                    p-3 rounded-xl outline-none bg-white text-black 
                    focus:ring-2 ring-1' type=''/>
                    <div className='mt-6 flex justify-center text-white'>
                        {
                          load?
                          <div className='bg-[#1C2E70] ring-2 ring-gray-300 flex items-center justify-center gap-2
                              rounded-xl py-3 w-full'>
                              <Spinner/>
                          </div>
                          :
                          <div onClick={Sub} className='bg-[#1C2E70] ring-2 ring-gray-300 flex items-center justify-center gap-2
                              rounded-xl py-3 w-full'>
                              <CiCirclePlus size={30}/>
                              <h1>Трак код бүртгэх</h1>
                          </div>
                        }
                    </div>
                </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }